#conversation-shell {
    height: 95vh;
    width: 100%;
    overflow: auto;
    /* display: inline-block;
    vertical-align: middle; */
}

.conversation-shell-paper {
    /* flex: 1;
    height: 95%;
    max-width: fit-content;
    display: inline-block;
    vertical-align: middle; */
    height: 95%;
    max-width: 25%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
}

#conversation-list {
    /* height: 68%;
    overflow: hidden auto;
    display: inline-block;
    vertical-align: middle;
    width: 35vh; */
    height: 85%;
    overflow-x: hidden;
    overflow-y: auto;
    display: inline-block;
    vertical-align: middle;
    width: 42vh;
}

.conversations-container {
    height: 95%;
    width: 50%;
    display: inline-block;
    vertical-align: middle;
}

.conversation-container-paper-1 {
    height: 100%;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background-color: rgba(52, 52, 52, 0.1);
}

.conversation-container-paper-2 {
    height: 100%;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background-color: white;
}

#conversation-message-myDiv {
    height: 85%;
    overflow: auto;
    overflow-x: hidden;
    background-color: #fafafa;
}

.conversation-header {
    background-color: #f0f2f5;
    height: 8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.conversation-header .spacer {
    flex-grow: 1;
}

.conversation-input {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgb(238, 238, 238);
}

.conversation-sidebar {
    height: 95%;
    width: 25%;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}

.conversation-sidebar-paper-1 {
    overflow: auto;
    height: 100%;
    width: 100%;
}

.conversation-container-paper-3 {
    height: 100%;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
}

#handle-botton {
    border: none;
}

#handle-botton-sidebar {
    border: none;
    background-color: white;
}

@media (max-width: 600px) {

    #conversation-shell {
        height: 90vh;
    }

    .conversation-shell-paper {
        /* position: relative;
        width: 100%; */
        max-width: unset;
        width: 100%;
    }

    #conversation-list {
        /* width: 80vh; */
        width: 100%;
    }

    /* #conversation-shell {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
    } */

    .conversations-container {
        height: 85%;
        width: 100%;
    }

    /* .conversation-container-paper-1 {
        width: 80vh;
    }

    .conversation-container-paper-2 {
        width: 80vh;
    }

    #conversation-message-myDiv {
        width: 80vh;
    }

    .conversation-header {
        width: 80vh;
    }

    .conversation-input {
        width: 80vh;
    } */

    .conversation-container {
        /* z-index: 20; */
        width: 100%;
    }

    .conversation-sidebar {
        width: 100%;
        z-index: 10;
    }

    /* #conversation-message-myDiv {
        height: 75%;
    } */

    .conversation-input {
        position: absolute;
    }

    /* #conversation-shell {
        padding-left: 30px;
        padding-right: 30px;
    }

    .conversation-shell-paper {
        width: 100%;
    }

    #conversation-list {
        width: 70vh;
    }

    .conversation-container-paper-2 {
        width: 425px;
    }

    #conversation-message-myDiv {
        width: 425px;
    }

    .conversation-header {
        width: 425px;
    }

    .conversation-input {
        width: 425px;
    }

    .conversation-sidebar {
        width: 100%;
    }

    .conversation-sidebar-paper-1 {
        width: 425px;
    } */
}